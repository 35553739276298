.highlight {
    background-color: yellow;
    font-weight: bold;
  }

  /* Add this to your CSS file */
.MuiToolbar-root {
  border: none !important;
}
/* Add this to your CSS file */
.css-riioma-MuiContainer-root {
  border: none;
}
