@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }

  .typingEffect {
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 2s steps(40, end) forwards;
  }
